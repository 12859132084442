
// 忽略文件中所有警告
// /* eslint-disable */
import { defineComponent, onMounted, ref, toRaw, watch } from 'vue'
import { message as Message, Modal } from 'ant-design-vue'
import { getContractPrint, updatePagesByContractId, getContractPrints } from '@/api/manage'
// import useRouter from '@/router'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { returnQuery } from '@/utils/index'
import PrintOrderTable from '@/views/component/PrintOrderTable.vue'


// import { useLoadMore } from 'vue-request';
// 忽略下一行警告
// eslint-disable-next-line
import PrintModel from '@/components/PrintModel.vue' // @ is an alias to /src
// const getFakeData = () => `https://randomuser.me/api/?results=5&inc=name,gender,email,nat&noinfo`;
export default defineComponent({
  name: 'Home',
  components: {
    PrintModel,
    PrintOrderTable
  },
  setup () {
    // const { dataList, loading, loadingMore, loadMore } = useLoadMore(getFakeData, {
    //   listKey: 'results',
    // });
    const store = useStore()
    const router = useRouter();
    const repositories = ref<any>([])
    const loading = ref(false)
    const userName = ref<any>('')
    const takeMode = ref('JSXJ')
    const activeKey = ref('1')
    const contractId = ref<any>('')
    const mark = ref<any>('1')
    const targetFile = ref<any>('1')
    const changeTabs = (e) => {
      activeKey.value = e
      if (e == '1') {
        // getContractPrintData(contractId.value)
      }
    }
    const refreshFile = async (id: any) => {
      loading.value = true
      const res = await updatePagesByContractId({ contractId: id })
      // getContractPrintData(contractId.value)
      let secondsToGo = 5;
      const modal = Modal.success({
        title: '刷新结果通知',
        content: `${res}  ${secondsToGo} 秒后关闭.`,
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: `${res}  ${secondsToGo} 秒后关闭.`,
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
      }, secondsToGo * 1000);
    }
    const handleClick = (e: Object) => {
      console.log('home组件函数触发====', e)
    }
    
    async function loadMore () {
      // Message.warning('成都疫情暂时无法邮寄，请联系客服1号或刘慧娟！')
      // return
      const data = toRaw(repositories.value)
      // let arr = []
      // data.forEach(item => {
      //   item.attachment.forEach(element => {
      //     if (element.checked) {
      //       arr.push(element)
      //     }
      //   });
      // })
      // const filterRepositories = data.filter(item => item.checked && item.pages)
      let arr = []
      data.forEach(item => {
        item.attachment.forEach(element => {
          if (element.checked && element.pages) {
            arr.push(element)
          }
        });
      })
      const filterRepositories = data
      if (!arr.length) {
        Message.warning('没有打印任务')
        return
      }
      store.commit('setRepositories', filterRepositories)
      let code = 'contractId=' + contractId.value + '&userName=' + userName.value + '&mark=' + mark.value
      router.push({
        path: '/print',
        query: {
          verify: window.btoa(escape(code)),
          takeMode: takeMode.value,
        }
      })
    }
    // 获取合同文件列表
    const getContractPrintData = async (id: string) => {
      loading.value = true
      // const datas = await getContractPrints({ contractIds: id })
      getContractPrints({
        contractIds:id,
        mark:mark.value,
        targetFile:targetFile.value
      }).then(res=> {
        console.log('getContractPrints====', res);
        if (res) {
  
          repositories.value = res
          if (repositories.value.length && repositories.value[0]) {
            const data = toRaw(repositories.value)
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              element.attachment.forEach(item => {
                item.checked = false
              });
            }
            repositories.value = data
            
          } else {
            // Message.error('添加失败')
          }
        } else {
          // Message.error('查询失败')
        }
        loading.value = false
      }).catch(err=> {
        console.log('getContractPrints--------',err);
        Message.error('请求失败')
        loading.value = false
      })
      
    }
    onMounted(() => {
      const data: any = router.currentRoute.value.query.verify
      if (data) {
        const query: any = returnQuery(data)
        // console.log('===========query',query)
        //mark 分类:合同打印1(默认)，用印2，检测报告打印3
        mark.value = query.mark
        targetFile.value = query.targetFile//检测报告打印参数
        contractId.value = query.id
        userName.value = query.userName
        // if(!contractId.value) {
        //   Message.error('没有合同id')
        // }
        if (query.tabs) {
          changeTabs(query.tabs)
        }
        if (!query.tabs && contractId.value) {
          // getContractPrintData(contractId.value)
        }
      }
      
      
      // handleClick('发送请求')
    })
    return {
      mark,
      targetFile,
      contractId,
      handleClick,
      repositories,
      checked: ref(true),
      loading,
      loadMore,
      activeKey,
      userName,
      takeMode,
      refreshFile,
      changeTabs,
     
    }
  }
})
