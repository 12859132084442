
import { SmileOutlined, DownOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref, computed, toRef } from 'vue';
import { queryContractPrintOrderList,printCenterAdsList } from '@/api/manage'
import { PrintOrderList } from '@/interface/print'
import LogisticsModal from '@/views/contractPrintOrder/modules/LogisticsModal.vue'
import { message as Message } from 'ant-design-vue'
import { paymentStatus, paymentStatusColor, payType,printOrign } from '../contractPrintOrder/modules/statusType'
// import { useRouter } from 'vue-router'
const columns = [
  {
    title:'创建时间',
    align:"center",
    dataIndex: 'createTime'
  },
  {
    title:'订单总金额',
    align:"center",
    dataIndex: 'totalAmount',
    customRender:function ({text}) {
      return !text ? "" : (text / 100) + '元'
    }
  },
  
  {
    title:'付款状态',
    align:"center",
    dataIndex: 'paymentStatus',
    slots: { customRender: 'paymentStatus' }
  },
  
  {
    title:'付款时间',
    align:"center",
    dataIndex: 'paymentTime',
    width: 120
  },
  {
    title: '付款方式',
    dataIndex: 'payType',
    align: 'center',
    key: 'payType',
    slots: { customRender: 'payType' },
  },
  {
    title:'物流单号',
    align:"center",
    dataIndex: 'deliverySn'
  },
  {
    title:'打印费',
    align:"center",
    dataIndex: 'printAmount',
    customRender:function ({text}) {
      return !text ? "" : (text / 100) + '元'
    }
  },
  {
    title:'快递费',
    align:"center",
    dataIndex: 'freightAmount',
    customRender:function ({text}) {
      return !text ? "" : (text / 100) + '元'
    }
  },
  {
    title:'附件数',
    align:"center",
    dataIndex: 'printItemNum',
  },
  {
    title:'收件人姓名',
    align:"center",
    dataIndex: 'receiveName'
  },
  {
    title:'收件人电话',
    align:"center",
    dataIndex: 'receivePhone'
  },
  {
    title:'文印中心',
    align:"center",
    dataIndex: 'printCenter'
  },
  {
    title: '操作',
    align: 'center',
    key: 'action',
    fixed: "right",
    width: 100,
    slots: { customRender: 'action' },
  },
];

export default defineComponent({
  name: 'PrintOrderTable',
  mixins: [],
  props: {
    userName: String,
    type:String,
  },
  setup(props, context) {
    const userName = toRef(props, 'userName')
    const type = toRef(props, 'type')
    const pageNo = ref<number>(1);
    const pageSize = ref<number>(10);
    const total = ref<number>(10);
    const dataSource = ref<any[]>([]);
    const loading = ref<boolean>(false);
    const logisticsModal = ref(null);
    const pagination = computed(() => ({
      total: total.value,
      current: pageNo.value,
      pageSize: pageSize.value,
    }));
    const queryParam = ref({
      status: '',
      deliverySn: '',
      printCenter:'',
      type:type.value
    });
    const searchReset = () => {
      queryParam.value = {
        status: '',
        deliverySn: '',
        printCenter:'',
        type:''
      }
      getContractPrintOrderList(1)
    }
    const getLogistics = async (record) => {
      if (!record.deliverySn) {
        Message.error('没有物流单号!')
        return
      }
      logisticsModal.value.getLogistics({ mailNos: record.deliverySn })
    }
    const handleTableChange = (pagination, filters, sorter) => {
      pageNo.value = pagination.current
      pageSize.value = pagination.pageSize
      getContractPrintOrderList()
    }
    const searchQuery = () => {

      getContractPrintOrderList(1)
    }
    const getContractPrintOrderList = async (page?: number) => {
      if (page) {
        pageNo.value = page
      }
     
      
      loading.value = true
      let paramse: PrintOrderList = {
        userName: userName.value,
        pageNo: pageNo.value,
        pageSize: pageSize.value,
        order:'desc',
        column: 'createTime',
        ...queryParam.value,
        type: queryParam.value.type,//合同打印1，用印2，检测报告打印3,
        printCenter:'*' + queryParam.value.printCenter +'*'
      }
      const data = await queryContractPrintOrderList(paramse)
      total.value = data.total
      dataSource.value = data.records
      loading.value = false
    }
    //文印中心--地址list
    //文印中心-地址
    const printCenterList = ref([]);
    const getPrintCenterList = async () => {
      const data = await printCenterAdsList({pageNo:1,pageSize:10});
      printCenterList.value = data.records
      // console.log('==========printCenterList',printCenterList)
    }

    onMounted(() => {
      getContractPrintOrderList()

      getPrintCenterList();
    })
    return {
      dataSource,
      columns,
      loading,
      userName,
      type,
      pagination,
      paymentStatus, paymentStatusColor, payType,
      getContractPrintOrderList,
      handleTableChange,
      searchQuery,
      queryParam,
      searchReset,
      logisticsModal,
      getLogistics,
      printOrign,
      printCenterList
    };
  },
  components: {
    SmileOutlined,
    DownOutlined,
    SearchOutlined,
    ReloadOutlined,
    LogisticsModal
  },
});
